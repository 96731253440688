import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../CenterBrandPage/centerbrandpage-jss';
import {
    setCenterUserAddVisible, setCenterUserAddData, setUserUpdate, getNewAuthorizationCenter
} from '../../../redux/modules/centerbrand';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import messageStyles from 'dan-styles/Messages.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AuthorizationListItem } from 'dan-components';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';

class CenterUserAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.centerUserAddUpdate == this.props.centerUserAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeCenterBrandAdd =  (Name) => async (event) => {
        const pointer = event.target.selectionStart;
        const element = event.target;

        const { centerUserAddData } = this.props;
        centerUserAddData[Name] = (Name == "IsStatus" ? event.target.checked : (Name == "Name" ? CapitalizeText(event.target.value) : (Name == "Email" ? ComplateLowerText(event.target.value) : event.target.value)));
        if(Name == "UserType" && centerUserAddData.UserType == 14){
            await this.props.getNewAuthorizationCenter().then(() => { });
        }
        this.props.setCenterUserAddData(centerUserAddData).then(() => { })

        window.requestAnimationFrame(() => {
            element.selectionStart = pointer;
            element.selectionEnd = pointer;
        });
    };

    handleSubmit(event) {
        event.preventDefault();
        this.props.setUserUpdate().then(() => { })
    }

     handleAuthorizationChange = (name) => {
        const { centerUserAddData } = this.props;
        centerUserAddData.Authorization.find(x => x.Name == name).IsStatus = !centerUserAddData.Authorization.find(x => x.Name == name).IsStatus;
        this.props.setCenterUserAddData(centerUserAddData).then(() => { })
      };

    render() {
        const { classes, centerUserAddVisible, centerUserAddData, centerUserAddLoading } = this.props;

        return (
            <Dialog
                open={centerUserAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'lg'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmit}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"Düzenleme ve Kayıt"}
                    </DialogTitle>
                    <DialogContent style={{ height: '100vh', overflow: 'hidden auto' }}>
                        {
                            (centerUserAddData.Id == "00000000-0000-0000-0000-000000000000" ?
                                <SnackbarContent style={{ backgroundColor: '#ffa000', borderRadius: 5, maxWidth: '100%', width: '100%', marginBottom: 5 }} className={classNames(classes.snackbar, messageStyles.bgSuccess)} message="Bilgilendirme: Şube açılışında kullanacağım Email burada girmiş olduğunuz Email adresidir. Şifre ise girilen telefon numarasının son 4 hanesidir!" />
                                : null)
                        }
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <Grid container spacing={1}>
                                    <Grid item md={12}>
                                        <Typography
                                            style={{
                                                fontSize: '1.5rem', fontWeight: 400, lineHeight: '1.5', borderBottom: '1px solid #636363', paddingBottom: 10, marginBottom: 10
                                            }}
                                            component="h3"
                                        >
                                            {'Kullanıcı Bilgileri'}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <RadioGroup
                                            aria-label="radio"
                                            name="radio"
                                            className={classes.group}
                                            style={{ display: 'block', width: '100%', marginLeft: 10 }}
                                            value={Number(centerUserAddData.UserType)}
                                            onChange={this.handleChangeCenterBrandAdd("UserType")}
                                        >
                                            <FormControlLabel style={{ marginRight: 20 }} value={6} control={<Radio />} label={"Call Center"} />
                                            <FormControlLabel style={{ marginRight: 20 }} value={9} control={<Radio />} label={"Raporlama"} />
                                            <FormControlLabel style={{ marginRight: 0 }} value={14} control={<Radio />} label={"Franchise"} />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <InputLabel htmlFor="name-simple">{'Adı Soyadı'}</InputLabel>
                                            <Input
                                                type="text"
                                                autoComplete="new-email"
                                                fullWidth
                                                required
                                                placeholder={'Adı Soyadı Giriniz'}
                                                defaultValue={centerUserAddData.Name}
                                                onChange={this.handleChangeCenterBrandAdd('Name')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <InputLabel htmlFor="name-simple">{'Email'}</InputLabel>
                                            <Input
                                                type="email"
                                                autoComplete="new-email"
                                                fullWidth
                                                required
                                                placeholder={'Email Giriniz'}
                                                defaultValue={centerUserAddData.Username}
                                                onChange={this.handleChangeCenterBrandAdd('Username')}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <InputMask
                                            mask="0 999 999 99 99"
                                            onFocus={event => { event.target.select() }}
                                            value={centerUserAddData.MobilePhone}
                                            onChange={this.handleChangeCenterBrandAdd("MobilePhone")}
                                        >
                                            {() => <TextField
                                                label={'Şube (Telefon)'}
                                                name={"Phone"}
                                                fullWidth
                                                required
                                                style={{ marginTop: 0 }}
                                                margin="normal"
                                                type="text"
                                            />}
                                        </InputMask>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <InputLabel htmlFor="name-simple">{'Şifre (4 haneli ve sadece rakamdan oluşabilir)'}</InputLabel>
                                            <Input
                                                type="text"
                                                autoComplete="new-email"
                                                fullWidth
                                                required
                                                placeholder={'Şifre Giriniz'}
                                                defaultValue={centerUserAddData.Password}
                                                onChange={this.handleChangeCenterBrandAdd('Password')}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <div className={classes.root} style={{ padding: 0 }}>
                                    <List subheader={(
                                        <Typography
                                            style={{
                                                fontSize: '1.5rem', fontWeight: 400, lineHeight: '1.5', borderBottom: '1px solid #636363', paddingBottom: 10, marginBottom: 10
                                            }}
                                            component="h3"
                                        >
                                            {'Yetkilendirme'}
                                        </Typography>
                                    )}
                                    >
                                        {
                                            (centerUserAddData.UserType == 14 ? centerUserAddData.Authorization.map((n, i) => <AuthorizationListItem data={n} onChange={(data) => this.handleAuthorizationChange(data)} />) : null)
                                        }
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={centerUserAddLoading} type={"submit"} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setCenterUserAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

const valueSelector = (state, props) => state.get('centerBrandReducer').centerUserAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    centerUserAddUpdate: value(state, props),
    centerUserAddVisible: state.get('centerBrandReducer').centerUserAddVisible,
    centerUserAddLoading: state.get('centerBrandReducer').centerUserAddLoading,
    centerUserAddData: state.get('centerBrandReducer').centerUserAddData
});

const mapDispatchToProps = {
    setCenterUserAddVisible,
    setCenterUserAddData,
    setUserUpdate,
    getNewAuthorizationCenter
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(CenterUserAdd)));
