import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import brand from 'dan-api/dummy/brand';
import { PapperBlock } from 'dan-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalInformation from '../Ortak/ModalInformation';
import Outer from '../../Templates/Outer';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../Templates/Decoration';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import styles from './centerbrandpage-jss';
import { getUser, formDataSetUser, userClear, setUserDelete, getUserUpdate } from '../../../redux/modules/user';
import {
  formDataSetCenterBrand, setCenterSettingsVisible, setCenterBrandVisible, modalClose,
  setCenterUserVisible, setCenterProductListVisible, setFranchiseOrderListVisible,
  getMainPageReport, setFranchiseRaportVisible, setFranchiseSadakatVisible
} from '../../../redux/modules/centerbrand';
import {
  setProductListVisible
} from '../../../redux/modules/stock';
import Hidden from '@material-ui/core/Hidden';
import cookie from 'react-cookies';
import jarvis from 'dan-images/jarvis.png';
import CenterSettings from './CenterSettings';
import CenterBrands from './CenterBrands';
import CenterUsers from './CenterUsers';
import CenterProductList from './CenterProductList';
import FranchiseOrderList from '../SettingPage/Franchise';
import CenterReport from './CenterReport';
import ProductList from '../SettingPage/Product/ProductList';
import {
  BarChart,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  PieChart, Pie, Cell,
  Sector,
  LabelList,
  Line
} from 'recharts';
import IconButton from '@material-ui/core/IconButton';
import javiapp from 'dan-images/javiapp.png';
import Sadakat from './Sadakat';
import { getAuthorization } from '../../../components/Authorization';

function CustomTooltip({ payload, label, active }) {
  if (active && payload != null) {
    return (
      <div style={{ backgroundColor: '#313131', borderRadius: 5, padding: 10 }}>
        <div style={{ display: 'flex', width: 200 }}>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Saat`}</div>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${label}:00`}</div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`İç Satış`}</div>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.NormalTotal.toFixed(2)}`}</div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Paket Satışı`}</div>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.PackageTotal.toFixed(2)}`}</div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`İç Satış Adet`}</div>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.NormalCount.toFixed(2)}`}</div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'left' }}>{`Paket Satışı Adet`}</div>
          <div style={{ fontSize: 12, color: 'white', width: '50%', textAlign: 'right' }}>{`${payload[0].payload.PackageCount.toFixed(2)}`}</div>
        </div>
      </div>
    );
  }

  return null;
}

class CenterMainPage extends React.Component {
  state = {
    fixed: false
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.getMainPageReport().then(() => { });
  }

  modalClose = () => {
    this.props.modalClose().then(() => { })
  };

  renderDataHour() {
    const { franchiseMainReportData } = this.props;

    var dataArray = [];
    franchiseMainReportData.map((n, i) => {
      dataArray.push({ Hour: n.Hour, NormalTotal: n.NormalTotal, PackageTotal: n.PackageTotal, NormalCount: n.NormalCount, PackageCount: n.PackageCount });
    })
    return dataArray;
  }

  renderColorfulLegendText(value, entry) {
    return <span style={{ color: 'white', fontSize: 12 }}>{value}</span>;
  }

  renderTotal(type) {
    const { franchiseMainReportData } = this.props;
    var totalIc = 0;
    var totalDis = 0;
    if (type == "total") {
      franchiseMainReportData.map((n, i) => {
        totalIc = totalIc + n.NormalTotal;
        totalDis = totalDis + n.PackageTotal
      })
    } else if (type == "count") {
      franchiseMainReportData.map((n, i) => {
        totalIc = totalIc + n.NormalCount;
        totalDis = totalDis + n.PackageCount;
      })
    } else if (type == "atp") {
      var totalAmountIc = 0;
      var totalCountIc = 0;

      var totalAmountDis = 0;
      var totalCountDis = 0;
      franchiseMainReportData.map((n, i) => {
        totalCountIc = totalCountIc + n.NormalCount;
        totalAmountIc = totalAmountIc + n.NormalTotal;

        totalCountDis = totalCountDis + n.PackageCount;
        totalAmountDis = totalAmountDis + n.PackageTotal;
      })

      if (totalAmountDis > 0 && totalCountDis > 0) {
        totalDis = totalAmountDis / totalCountDis;
      }
      if (totalAmountIc > 0 && totalCountIc > 0) {
        totalIc = totalAmountIc / totalCountIc;
      }
    }
    if (type == "count") {
      return (
        <div style={{ display: 'grid' }}>
          <span style={{ fontSize: 17, width: '100%' }}>{"İç Adisyon : "}{totalIc.toFixed(0)}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Paket Adisyon : "}{totalDis.toFixed(0)}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Toplam Adisyon : "}{(totalIc + totalDis).toFixed(0)}</span>
        </div>
      );
    } else if (type == "atp") {
      return (
        <div style={{ display: 'grid' }}>
          <span style={{ fontSize: 17, width: '100%' }}>{"İç Atp : "}{totalIc.toFixed(2)}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Paket Atp : "}{totalDis.toFixed(2)}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Toplam Atp : "}{((totalIc + totalDis) / 2).toFixed(2)}</span>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'grid' }}>
          <span style={{ fontSize: 17, width: '100%' }}>{"İç Satış : "}{totalIc.toFixed(2)}{" ₺"}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Paket Satış : "}{totalDis.toFixed(2)}{" ₺"}</span>
          <span style={{ fontSize: 17, width: '100%' }}>{"Toplam Satış : "}{(totalIc + totalDis).toFixed(2)}{" ₺"}</span>
        </div>
      );
    }
  }

  render() {

    const {
      classes,
      modalOpen,
      modalTitle,
      modalText
    } = this.props;

    return (
      <Outer style={{ position: 'relative' }}>
        <ModalInformation event={this.modalClose} open={modalOpen} title={modalTitle} text={modalText} />
        <CenterSettings classes={classes} />
        <CenterBrands classes={classes} />
        <CenterUsers classes={classes} />
        <CenterProductList classes={classes} />
        <FranchiseOrderList classes={classes} />
        <ProductList classes={classes} />
        <CenterReport classes={classes} />
        <Sadakat classes={classes} />
        <Grid spacing={1} container style={{ height: '95vh', zIndex: 99, width: '95%' }}>
          <Grid item md={7}>
            <div style={{ marginBottom: 8, display: 'flex', width: '100%', height: 'calc(40% - 4px)' }}>
              <div style={{ marginRight: 4, borderRadius: 5, position: 'relative', backgroundColor: '#424242', height: '100%', width: '33.33%', position: 'relative' }}>
                <Icon style={{ color: '#2d98da', fontSize: 50, position: 'absolute', left: 15, top: 10 }}>{"payments"}</Icon>
                <div style={{ fontWeight: 500, textAlign: 'center', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', height: '100%' }}>
                  {this.renderTotal("total")}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: 'white', borderRadius: 5, color: '#424242', fontWeight: 600, fontSize: 13, position: 'absolute', right: 15, bottom: 10 }}>{"Toplam Ciro"}</div>
              </div>
              <div style={{ borderRadius: 5, backgroundColor: '#424242', marginLeft: 4, borderRadius: 5, height: '100%', width: '33.33%', display: 'flex', position: 'relative' }}>
                <Icon style={{ color: '#f7b731', fontSize: 50, position: 'absolute', left: 15, top: 10 }}>{"widgets"}</Icon>
                <div style={{ fontWeight: 500, textAlign: 'center', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', height: '100%' }}>
                  {this.renderTotal("count")}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: 'white', borderRadius: 5, color: '#424242', fontWeight: 600, fontSize: 13, position: 'absolute', right: 15, bottom: 10 }}>{"Toplam Adisyon"}</div>
              </div>
              <div style={{ borderRadius: 5, backgroundColor: '#424242', marginLeft: 4, borderRadius: 5, height: '100%', width: '33.33%', display: 'flex', position: 'relative' }}>
                <Icon style={{ color: '#0fb9b1', fontSize: 50, position: 'absolute', left: 15, top: 10 }}>{"flatware"}</Icon>
                <div style={{ fontWeight: 500, textAlign: 'center', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', height: '100%' }}>
                  {this.renderTotal("atp")}
                </div>
                <div style={{ paddingLeft: 10, paddingRight: 10, backgroundColor: 'white', borderRadius: 5, color: '#424242', fontWeight: 600, fontSize: 13, position: 'absolute', right: 15, bottom: 10 }}>{"Ortalama Tutar (ATP)"}</div>
              </div>
            </div>
            <div style={{ position: 'relative', marginTop: 8, borderRadius: 5, backgroundColor: '#424242', display: 'block', width: '100%', height: 'calc(60% - 4px)' }}>
              <div style={{ color: 'white', padding: 5, display: 'flex', justifyContent: 'center' }}>{("Saat Bazında Toplam Satış ve Adet Grafiği (Anlık)")}</div>
              <ResponsiveContainer>
                <ComposedChart
                  data={this.renderDataHour()}
                  margin={{
                    top: 15,
                    right: 15, left: -20,
                    bottom: 65
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={"#f7b731"} stopOpacity={1} />
                      <stop offset="95%" stopColor={"#f7b731"} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="colorUp" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={"#0fb9b1"} stopOpacity={1} />
                      <stop offset="95%" stopColor={"#0fb9b1"} stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis interval={0} angle={-45} textAnchor="end" dataKey="Hour" tickLine={false} label={{ size: 12 }} />
                  <YAxis axisLine={false} tickSize={3} tickLine={false} tick={{ stroke: 'none' }} />
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <CartesianAxis vertical={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend verticalAlign={"bottom"} formatter={this.renderColorfulLegendText} />
                  <Line strokeWidth={2} type="monotone" stackId="a" name="İç Satış" dataKey="NormalTotal" stroke="url(#colorUv)" />
                  <Line strokeWidth={2} type="monotone" stackId="a" name="Paket Satışı" dataKey="PackageTotal" stroke="url(#colorUp)" />
                  <Bar stackId="b" name="İç Satış Adet" dataKey="NormalCount" fill="url(#colorUv)" />
                  <Bar stackId="b" name="Paket Satışı Adet" dataKey="PackageCount" fill="url(#colorUp)" />
                </ComposedChart>
              </ResponsiveContainer>
              <div style={{ position: 'absolute', left: 10, bottom: 5 }}>
                <IconButton style={{ backgroundColor: '#313131', borderRadius: 5, width: 100, height: 35 }} onClick={() => this.props.getMainPageReport().then(() => { })}>
                  <Icon style={{ color: 'white' }}>{"refresh"}</Icon>
                  <span style={{ fontSize: 15, marginLeft: 10 }}>{"Yenile"}</span>
                </IconButton>
              </div>
              <div style={{ position: 'absolute', right: 10, bottom: 5 }}>
                <IconButton style={{ textAlign: 'center', backgroundColor: '#c0392b', borderRadius: 5, width: 90, height: 35 }} onClick={() => (confirm('Çıkış yapmak istediğinize emin misiniz?') ? this.props.history.push('/') : null)}>
                  <Icon style={{ color: 'white' }}>{"close"}</Icon>
                  <span style={{ fontSize: 15, marginLeft: 5 }}>{"Çıkış"}</span>
                </IconButton>
              </div>
            </div>
          </Grid>
          {(
            cookie.load('UserType') == "CenterReporting" ?
              <Grid item md={5}>
                <Button fullWidth onClick={() => this.props.setFranchiseRaportVisible(true).then(() => { })} variant="contained" color="default" style={{ marginBottom: 7, position: 'relative', height: '100%', backgroundColor: '#ff793f', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                  <Icon style={{ fontSize: 70 }}>{"query_stats"}</Icon>
                  <Typography style={{ fontSize: 20, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Franchise Raporları"}</Typography>
                </Button>
              </Grid>
              : <Grid item md={3}>
                <Button disabled={!getAuthorization('FranchiseProductAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseProductAuthority') ? this.props.setProductListVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ marginBottom: 7, position: 'relative', height: '33.33%', backgroundColor: '#4FB99F', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                  <Icon style={{ fontSize: 50 }}>{"category"}</Icon>
                  <Typography style={{ fontSize: 18, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Ürünler"}</Typography>
                </Button>
                <Button disabled={!getAuthorization('FranchiseReportAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseReportAuthority') ? this.props.setFranchiseRaportVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ marginBottom: 7, position: 'relative', height: 'calc(33.33% - 7px)', backgroundColor: '#ff793f', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                  <Icon style={{ fontSize: 70 }}>{"query_stats"}</Icon>
                  <Typography style={{ fontSize: 20, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Franchise Raporları"}</Typography>
                </Button>
                <Button disabled={!getAuthorization('FranchiseSadakatAuthority')} fullWidth onClick={() =>  (getAuthorization('FranchiseSadakatAuthority') ? this.props.setFranchiseSadakatVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ position: 'relative', height: 'calc(33.33% - 7px)', backgroundColor: '#ffb142', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                  <Icon style={{ fontSize: 70 }}>{"campaign"}</Icon>
                  <Typography style={{ fontSize: 20, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"JaviSadakat Ayarları"}</Typography>
                </Button>
              </Grid>
          )}
          {(
            cookie.load('UserType') == "CenterReporting" ? null : <Grid item md={2}>
              <div style={{ borderRadius: 5, display: 'block', width: '100%', height: '100%' }}>
                <div style={{ marginBottom: 7, borderRadius: 5, backgroundColor: '#16a085', display: 'flex', width: '100%', height: 'calc(25% - 7px)', justifyContent: 'center', alignItems: 'center' }}>
                  <Button disabled={!getAuthorization('FranchiseSupplyAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseSupplyAuthority') ? this.props.setFranchiseOrderListVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#fc5c65', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                    <Icon style={{ fontSize: 50 }}>{"grading"}</Icon>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Tedarik İşlemleri"}</Typography>
                  </Button>
                </div>
                <div style={{ marginTop: 7, borderRadius: 5, backgroundColor: '#16a085', display: 'flex', width: '100%', height: '25%', justifyContent: 'center', alignItems: 'center' }}>
                  <Button disabled={!getAuthorization('FranchiseUserAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseUserAuthority') ? this.props.setCenterUserVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#95a5a6', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                    <Icon style={{ fontSize: 50 }}>{"group"}</Icon>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Kullanıcılar"}</Typography>
                  </Button>
                </div>
                <div style={{ marginTop: 7, marginBottom: 7, borderRadius: 5, backgroundColor: '#16a085', display: 'flex', width: '100%', height: 'calc(25% - 7px)', justifyContent: 'center', alignItems: 'center' }}>
                  <Button disabled={!getAuthorization('FranchiseBrandAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseBrandAuthority') ? this.props.setCenterBrandVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#34495e', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                    <Icon style={{ fontSize: 50 }}>{"storefront"}</Icon>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Şubeler"}</Typography>
                  </Button>
                </div>
                <div style={{ borderRadius: 5, backgroundColor: '#16a085', display: 'flex', width: '100%', height: 'calc(25% - 7px)', justifyContent: 'center', alignItems: 'center' }}>
                  <Button disabled={!getAuthorization('FranchiseSettingAuthority')} fullWidth onClick={() => (getAuthorization('FranchiseSettingAuthority') ? this.props.setCenterSettingsVisible(true).then(() => { }) : null)} variant="contained" color="default" style={{ position: 'relative', height: '100%', backgroundColor: '#2d98da', color: 'white', borderRadius: 0, textTransform: 'capitalize', display: 'block' }}>
                    <Icon style={{ fontSize: 50 }}>{"settings"}</Icon>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }} className={classes.buttonMenuTitle} variant="subtitle1">{"Ayarlar"}</Typography>
                  </Button>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Outer >
    );
  }
}

const mapStateToProps = state => {
  return {
    modalOpen: state.get('centerBrandReducer').form_data.modalOpen,
    modalText: state.get('centerBrandReducer').form_data.modalText,
    modalTitle: state.get('centerBrandReducer').form_data.modalTitle,

    franchiseMainReportData: state.get('centerBrandReducer').franchiseMainReportData
  };
};

const mapDispatchToProps = {
  getUser,
  formDataSetUser,
  userClear,
  setUserDelete,
  getUserUpdate,
  modalClose,
  formDataSetCenterBrand,
  setCenterSettingsVisible,
  setCenterBrandVisible,
  setCenterUserVisible,
  setCenterProductListVisible,
  setFranchiseOrderListVisible,
  setProductListVisible,
  getMainPageReport,
  setFranchiseRaportVisible,
  setFranchiseSadakatVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(CenterMainPage)));
