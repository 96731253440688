import cookie from 'react-cookies';

export function getAuthorization(name) {
  var autList = true;
  if (cookie.load('UserType') == 'Cashier' || cookie.load('UserType') == 'Waiter' || cookie.load('UserType') == 'FranchiseUser') {
    autList = cookie.load('Authorization');
    var filter = autList.filter(x=>x.Name == name).length;
    if(filter > 0){
      autList = autList.find(x=>x.Name == name).IsStatus;
    }else {
      autList = false;
    }
    
  }
  return autList;
}
