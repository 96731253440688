import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Settings/settings-jss';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Switch from "react-switch";
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
const uuidv4 = require('uuid/v4');
import Select from '@material-ui/core/Select';
import {
  formDataSetLicence, setLicenceVisible, ModuleOpen, setSubscriptionData,
  PaymentDetailOpen, CardOpen, getPaymentUrl, setCardInfoDelete,
  setManuelCharge, setCardInfoArrange, setModalLicense,
  InvoiceVisible, getInvoiceHeader, setInvoiceHeader, setInvoiceData, setSendLinkVisible, setSendPaymentLink,
  setLicenceData
} from '../../../redux/modules/licence';
import ModalInformation from '../Ortak/ModalInformation';
import cookie from 'react-cookies';
import {
  createSelector
} from 'reselect';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { PricingCard, ModuleCard } from 'dan-components';
import { getLicence, trMoneyConvert } from '../../../function/GeneralFunction';
import bgCover from 'dan-images/petal_bg.svg';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import paynet from 'dan-images/paynet.png';
import MenuItem from '@material-ui/core/MenuItem';

const returnModule = (Id) => {
  switch (Id) {
    case "a388d94e-6e62-4c8e-bcc8-92f9d5b5a67c":
      return true;
    case "54ced956-358d-4a38-a8e7-f79ca670eb69":
      return true;
    case "6e98ccb9-08b3-47ce-840e-2745ad3bf706":
      return true;
    case "c4297a5e-3e5b-4255-8022-14d4163e66c9":
      return true;
    case "9b5db60d-51a8-480d-8ea7-acc1ce3f88c0":
      return true;
    case "90f4970d-4698-4080-ba55-5ac2d174fcdb":
      return true;
    case "bd2df397-1ec8-4298-bfd3-d15f516ce4cf":
      return true;
    case "a81eecbe-62a0-43b3-a467-f2bbf622dfa3":
      return true;
    default:
      return false;
  }
}

function TextMaskCustomNumber(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TextMaskCustomPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}


function TextMaskCustomDate(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Licence extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visibleModuleOnay: false };
  }

  handleFocus = (event) => event.target.select();

  async componentDidMount() {
    await this.props.getInvoiceHeader().then(() => { })
  }

  modalClose = () => {
    this.props.formDataSetLicence("modalLicenceOpen", false).then(() => { })
  };

  renderModule() {
    const { classes, licenceData } = this.props;

    return (
      <Dialog
        open={this.props.IsModuleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Modül Detayları"}
          <Typography className={classes.instructions} style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>
            {"Modül Satın almalar için JaviPos teknik destek ile irtibata geçiniz!"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
              <TableHead style={{ backgroundColor: '#212121' }}>
                <TableRow>
                  <TableCell padding="dense">{"Modül Adı"}</TableCell>
                  <TableCell align="right" padding="dense">{"Fiyat"}</TableCell>
                  <TableCell align="right" padding="dense">{"Durum"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  licenceData.ModuleList.ModuleListItem.map((n, i) => {
                    var isStatus = licenceData.SubscriptionInfo.Module.filter(t => t.Id == n.Id).length != 0;
                    return (
                      <TableRow hover key={n.id}>
                        <TableCell padding="dense">
                          {n.Name}
                        </TableCell>
                        <TableCell align="right" padding="dense">
                          {trMoneyConvert(n.Price,2) + "₺"}
                        </TableCell>
                        <TableCell align="right" padding="dense">
                          <span style={{ backgroundColor: (isStatus ? '#16a085' : '#e74c3c'), padding: 3, borderRadius: 3 }}>{isStatus ? "Aktif" : "Pasif"}</span>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.ModuleOpen().then(() => { })} color="primary">
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderLinkPayment() {
    const { classes, sendLinkVisible, sendLinkData } = this.props;

    return (
      <Dialog
        open={sendLinkVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Ödeme Linki Gönderme İşlemleri"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {(sendLinkData != null ?
              <div style={{ fontWeight: 500, fontSize: '0.875rem', textAlign: 'center', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10, backgroundColor: '#ffa000', color: 'rgba(0, 0, 0, 0.87)' }}>
                {sendLinkData.val_date} {"tarihli"} {sendLinkData.status_desc} {"durumundaki"} {'₺' + sendLinkData.amount.toFixed(2)} {"tutarındaki ödenmemiş faturanız için sistemde kayıtlı olan cep telefonu numarasına Kredi Kartı / Banka Kartı ile ödeme yapabileceğiniz ödeme linki gönderilecektir. Ödemenizi 1 saat içerisinde link üzerinden yapabilirsiniz. Ödemenizi yaptığınızda abonelik süreniz otomatik olarak uzatılacaktır. JaviPos uygulamamıza çıkış giriş yapmanız yeterlidir. İyi Çalışmalar Dileriz!"}
              </div>
              : null)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#16a085', color: 'white' }} onClick={() => this.props.setSendPaymentLink(sendLinkData.amount, sendLinkData.invoice_id).then(() => { })} color="primary">
            {"GÖNDER"}
          </Button>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.setSendLinkVisible(false).then(() => { })} color="primary">
            {"İPTAL"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderPaymentDetail() {
    const { classes, licenceData } = this.props;

    if (licenceData.subscription_detail == null) {
      return null;
    }

    return (
      <Dialog
        open={this.props.IsPaymentDetailOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}
      >
        {this.renderLinkPayment()}
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Ödeme Planı ve Detayları"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.rootTable}>
              <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{"Ödeme Tarihi"}</TableCell>
                    <TableCell align="left">{"Tutar"}</TableCell>
                    <TableCell align="left">{"Durumu"}</TableCell>
                    <TableCell align="right">{"İşlem"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    licenceData.subscription_detail.Data.plan.sort((a, b) => b.plan_id - a.plan_id).map((n, i) => {
                      return (
                        <TableRow hover key={i}>
                          <TableCell align="left">{n.val_date}</TableCell>
                          <TableCell align="left">{trMoneyConvert(n.amount,2)}</TableCell>
                          <TableCell align="left">{n.status_desc}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Ödemeyi Tekrar Dene">
                              <Button style={{ textTransform: 'capitalize', display: (n.status == 4 ? '' : 'none') }} color="primary" variant="outlined" onClick={() => this.props.setManuelCharge(n.invoice_id).then(() => { })} className={classes.stepper_button}>
                                <Icon>{"repeat"}</Icon>
                                {"Ödemeyi Tekrar Dene"}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Link İle Ödeme Yap">
                              <Button style={{ textTransform: 'capitalize', display: (n.status == 4 ? '' : 'none') }} color="default" variant="outlined" onClick={() => this.props.setSendLinkVisible(true, n).then(() => { })} className={classes.stepper_button}>
                                <Icon>{"repeat"}</Icon>
                                {"Link İle Ödeme Yap"}
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.PaymentDetailOpen().then(() => { })} color="primary">
            {"Çıkış"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderCard() {
    const { classes, cardList, licenceData } = this.props;

    return (
      <Dialog
        open={this.props.IsCardOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
        PaperProps={{ classes: { root: classes.dialogPaperAbonelik } }}
      >
        <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Kart İşlemleri"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.rootTable}>
              <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align={"left"}>{"Kart Sahibi"}</TableCell>
                    <TableCell align={"left"}>{"Kart No"}</TableCell>
                    <TableCell align={"center"}>{"Varsayılan"}</TableCell>
                    <TableCell align={"right"}>{"İşlem"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (cardList != null ?
                      cardList.map((n, i) => {
                        return (
                          <TableRow hover key={i}>
                            <TableCell align={"left"}>{n.card_holder_name_mask}</TableCell>
                            <TableCell align={"left"}>{n.card_no_mask}</TableCell>
                            <TableCell align={"center"}>{(n.order_no == 1 ? "✓" : "✗")}</TableCell>
                            <TableCell align="right">
                              <Tooltip title="Varsayılan Yap">
                                <IconButton style={{ marginRight: 5, backgroundColor: '#3e3e3e', display: (n.order_no == 1 ? 'none' : '') }} onClick={() => this.props.setCardInfoArrange(n.card_id).then(() => { })}>
                                  <Icon>{"done"}</Icon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Kart Sil">
                                <IconButton style={{ backgroundColor: '#3e3e3e', display: (n.order_no == 1 ? 'none' : '') }} onClick={() => this.props.setCardInfoDelete(n.card_id).then(() => { })}>
                                  <Icon>{"delete_outline"}</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )
                      })
                      : null)
                  }
                </TableBody>
              </Table>
              {
                (cardList == null ? <div style={{ color: '#FFC107', height: 50, textAlign: 'center' }}>{"Kart Bilgisi Bekleniyor"}<br />{"Lütfen Kart Ekle butonundan kart ekleyiniz!"}</div> : null)
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.CardOpen(false).then(() => { })} color="primary">
            {"Çıkış"}
          </Button>
          <Button onClick={() => this.props.CardOpen(false).then(() => {
            this.props.setLicenceVisible(false).then(() => { window.open(licenceData.url, "_blank") })
          })} color="secondary" variant="contained" autoFocus>
            {"Kart Ekle"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderPrice() {
    const { licenceData } = this.props;
    var price = licenceData.SubscriptionInfo.DefaultPrice;
    licenceData.SubscriptionInfo.Module.map((n, i) => {
      price = price + n.Price;
    })
    return price.toFixed(2);
  }

  renderLicencePrice(data) {
    return data.subscription_detail.Data.amount;
  }

  clickInvoiceOpen = async () => {
    await this.props.getInvoiceHeader().then(() => { })
    this.props.InvoiceVisible(true).then(() => { })
  };

  handleChangeInvoice = (Name) => event => {
    const { invoiceData } = this.props;
    invoiceData[Name] = event.target.value;
    this.props.setInvoiceData(invoiceData).then(() => { })
  };

  clickInvoiceSave = async () => {
    const { invoiceData } = this.props;
    var count = Object.keys(invoiceData).length;
    for (let index = 0; index < count; index++) {
      const element = Object.values(invoiceData)[index];
      if (element == null) {
        await this.props.setModalLicense("Lütfen tüm alanları eksiksiz doldurunuz!").then(() => { })
        return;
      }
      if (element.length == 0) {
        await this.props.setModalLicense("Lütfen tüm alanları eksiksiz doldurunuz!").then(() => { })
        return;
      }
    }
    await this.props.setInvoiceHeader(invoiceData).then(() => { })
  };

  renderInvoice() {
    const { classes, invoiceData } = this.props;

    if (invoiceData == null) {
      return null;
    }

    return (
      <Dialog
        open={this.props.invoiceVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
          {"Fatura Bilgileri"}
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <DialogContentText id="alert-dialog-description">
            <div style={{ textAlign: 'center', fontSize: 12, color: 'white' }}>{"Abonelik başlatabilmeniz için fatura bilgilerini doldurmanız gerekmektedir. Faturalandırmalar otomatik yapıldığından bu bilgiler zorunludur."}</div>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Ticari Ünvan"}</InputLabel>
                  <Input fullWidth defaultValue={invoiceData.CompanyTitle} onChange={this.handleChangeInvoice("CompanyTitle")} />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Vergi Dairesi"}</InputLabel>
                  <Input fullWidth defaultValue={invoiceData.TaxOffice} onChange={this.handleChangeInvoice("TaxOffice")} />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Vergi Numarası"}</InputLabel>
                  <Input fullWidth defaultValue={invoiceData.TaxNo} onChange={this.handleChangeInvoice("TaxNo")} />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Adres"}</InputLabel>
                  <Input fullWidth multiline defaultValue={invoiceData.Address} onChange={this.handleChangeInvoice("Address")} />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"E-posta"}</InputLabel>
                  <Input fullWidth defaultValue={invoiceData.Eposta} onChange={this.handleChangeInvoice("Eposta")} />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="name-simple">{"Telefon"}</InputLabel>
                  <Input inputComponent={TextMaskCustomPhone} fullWidth defaultValue={invoiceData.Phone} onChange={this.handleChangeInvoice("Phone")} />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: '#e74c3c', color: 'white' }} onClick={() => this.props.InvoiceVisible(false).then(() => { })} color="primary">
            {"İptal"}
          </Button>
          <Button onClick={() => this.clickInvoiceSave()} color="secondary" variant="contained" autoFocus>
            {"Kaydet"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  clickGetPaymentUrl = async () => {
    await this.props.getInvoiceHeader().then(() => { })

    const { invoiceData } = this.props;
    var count = Object.keys(invoiceData).length;
    for (let index = 0; index < count; index++) {
      const element = Object.values(invoiceData)[index];
      if (element == null) {
        this.props.InvoiceVisible(true).then(() => { })
        return;
      }
      if (element.length == 0) {
        this.props.InvoiceVisible(true).then(() => { })
        return;
      }
    }
    this.props.getPaymentUrl().then(() => { })
  };

  _handleLicenceData = (Name) => event => {
    const {
      licenceData
    } = this.props;
    if (Name == "Period") {
      licenceData.SubscriptionInfo.Period = event.target.value;
    }
    this.props.setLicenceData(licenceData).then(() => { })
  };

  renderTotalPrice() {
    const {
      licenceData
    } = this.props;

    let totalPrice = 0;
    var price = parseFloat(licenceData.status != null ? this.renderLicencePrice(licenceData) : this.renderPrice());

    if (licenceData.SubscriptionInfo.Period == 1) {
      var moPrice = Math.floor(price - (price * 0.2));
      totalPrice = moPrice * 12;
    } else {
      totalPrice = price;
    }

    return trMoneyConvert(totalPrice,2);
  }

  render() {
    const {
      classes,
      licenceData,
      invoiceData
    } = this.props;

    if (licenceData == null || invoiceData == null) {
      return null;
    }

    return (
      <Dialog
        fullScreen
        open={this.props.IsLicenceVisible}
        TransitionComponent={Transition}
      >
        {this.renderModule()}
        {this.renderPaymentDetail()}
        {this.renderCard()}
        {this.renderInvoice()}
        <ModalInformation event={this.modalClose} open={this.props.modalLicenceOpen} title={this.props.modalLicenceTitle} text={this.props.modalLicenceText} />
        <AppBar className={classes.appBar} style={{ position: 'fixed', top: 0, width: '100%' }}>
          <Toolbar>
            <Button color="inherit" onClick={() => this.props.setLicenceVisible(false).then(() => { })}>
              {"Geri"}
            </Button>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {"Abonelik ve Ödeme İşlemleri"}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.detailWrap} style={{ height: '100vh', backgroundColor: 'rgb(48, 48, 48)', marginTop: 64, paddingLeft: 10, paddingRight: 10 }}>
          <div style={{ width: '100%', height: 65, backgroundColor: '#4b4b4b', textAlign: 'center', fontSize: 20, color: 'white', display: 'inline-grid', alignContent: 'center' }}>
            <span style={{ fontSize: 14 }}>{"Abonelik süresi uzatma işlemleri sistem tarafından otomatik yapılmaktadır!"}</span>
          </div>
          {
            (
              licenceData.status == 0 ?
                <div style={{ color: '#FFC107', height: 50, textAlign: 'center', fontSize: 14, marginTop: 10 }}>{"Kart Bilgisi Bekleniyor"}<br />{"Lütfen Kart Ekle butonundan kart ekleyiniz!"}</div>
                :
                null
            )
          }
          <Divider />
          <div style={{ backgroundColor: '#424242', padding: 5, marginBottom: 10, marginTop: 10 }}>
            <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {"Abonelik İşlemleri"}
            </div>
            <div style={{ alignItems: 'center', display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
              <div style={{ fontSize: 14, textAlign: 'left', width: '50%' }}>{"Bayi Kodu"}</div>
              <div style={{ fontWeight: 600, textAlign: 'right', width: '50%' }}>{""}</div>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
              <div style={{ fontSize: 14, textAlign: 'left', width: '50%' }}>{"Abone Kodu"}</div>
              <div style={{ fontWeight: 600, textAlign: 'right', width: '50%' }}>{cookie.load('LicenceNumber')}</div>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
              <div style={{ fontSize: 14, textAlign: 'left', width: '90%' }}>{"Abonelik Periyodu"}</div>
              <div style={{ fontWeight: 600, textAlign: 'right', width: '10%' }}>
                {
                  (
                    licenceData.status == null ?
                      <Select
                        value={licenceData.SubscriptionInfo.Period}
                        onChange={this._handleLicenceData("Period")}
                        fullWidth
                      >
                        <MenuItem value={0}>{"Aylık"}</MenuItem>
                        <MenuItem value={1}>{"Yıllık (%20 İndirim)"}</MenuItem>
                      </Select>
                      :
                      (licenceData.SubscriptionInfo.Period == 0 ? "Aylık" : "Yıllık")
                  )
                }
              </div>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', padding: 10, borderBottom: '1px solid rgb(99, 99, 99)' }}>
              <div style={{ fontSize: 14, textAlign: 'left', width: '50%' }}>{"Abonelik Fiyatı"}</div>
              <div style={{ fontWeight: 600, textAlign: 'right', width: '50%' }}>{this.renderTotalPrice()}{"₺"}</div>
            </div>
            <div style={{ display: 'flex', padding: 10 }}>
              <div style={{ textAlign: 'right', width: '100%' }}>
                {
                  (
                    licenceData.status == null ?
                      <Button style={{ borderColor: 'transparent', textTransform: 'capitalize', color: 'white', height: 35, width: '20%', backgroundColor: '#16a085' }} color="inherit" variant="outlined" onClick={this.clickGetPaymentUrl}>
                        {"Abonelik Başlat"}
                      </Button>
                      :
                      null
                  )
                }
              </div>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item md={4} style={{ alignContent: 'center' }}>
              <Button fullWidth style={{ textTransform: 'capitalize', borderRadius: 5 }} variant="contained" onClick={this.clickInvoiceOpen}>
                <Icon style={{ color: '#424242', marginRight: 10 }}>{"description"}</Icon>
                {"Fatura Bilgileri"}
              </Button>
            </Grid>
            <Grid item md={4} style={{ alignContent: 'center' }}>
              <Button fullWidth disabled={licenceData.subscription_id == "" ? true : false} style={{ textTransform: 'capitalize', borderRadius: 5 }} variant="contained" onClick={() => this.props.PaymentDetailOpen().then(() => { })}>
                <Icon style={{ color: '#424242', marginRight: 10 }}>{"payments"}</Icon>
                {"Ödeme Detayları"}
              </Button>
            </Grid>
            <Grid item md={4} style={{ alignContent: 'center' }}>
              <Button fullWidth disabled={licenceData.subscription_id == "" ? true : false} style={{ textTransform: 'capitalize', borderRadius: 5 }} variant="contained" onClick={() => this.props.CardOpen(true).then(() => { })}>
                <Icon style={{ color: '#424242', marginRight: 10 }}>{"credit_card"}</Icon>
                {"Kart İşlemleri"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ backgroundColor: '#424242', padding: 5, marginBottom: 15, marginTop: 10 }}>
            <div style={{ marginBottom: 10, width: '100%', padding: 10, borderBottom: '1px solid rgba(209, 216, 224,.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {"Fatura Bilgileri"}
            </div>
            <Table className={classNames(classes.table, classes.bordered)} style={{ marginTop: 0 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '15%' }} align={"left"}>{"Vergi Dairesi"}</TableCell>
                  <TableCell style={{ width: '15%' }} align={"left"}>{"Vergi Numarası"}</TableCell>
                  <TableCell style={{ width: '40%' }} align={"left"}>{"Adres"}</TableCell>
                  <TableCell style={{ width: '15%' }} align={"left"}>{"E-Posta"}</TableCell>
                  <TableCell style={{ width: '15%' }} align={"left"}>{"Telefon"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={{ padding: '2px 5px 2px 5px' }}>
                    {invoiceData.TaxOffice}
                  </TableCell>
                  <TableCell align="left" style={{ padding: '2px 5px 2px 5px' }}>
                    {invoiceData.TaxNo}
                  </TableCell>
                  <TableCell align="left" style={{ padding: '2px 5px 2px 5px' }}>
                    {invoiceData.Address}
                  </TableCell>
                  <TableCell align="left" style={{ padding: '2px 5px 2px 5px' }}>
                    {invoiceData.Eposta}
                  </TableCell>
                  <TableCell align="left" style={{ padding: '2px 5px 2px 5px' }}>
                    {invoiceData.Phone}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div style={{ backgroundColor: 'rgba(48,48,48,1)', alignItems: 'center', borderRadius: 10, border: '1px solid rgba(255,255,255,.3)', width: '100%', color: 'white', display: 'flex', padding: 5 }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingRight: 10, borderRight: '1px solid white' }}>
              <Icon style={{ fontSize: 45, color: 'white', width: '100%' }}>{"verified_user"}</Icon>
            </div>
            <div style={{ fontSize: 14, width: '100%' }}>
              <div style={{ fontSize: 12, width: '100%', textAlign: 'initial', paddingLeft: 10 }}>{"Abonelik Kalan : "}<span style={{ fontSize: 14, fontWeight: '600' }}>{getLicence("date")}{" Gün"}</span></div>
              <div style={{ fontWeight: '600', fontSize: 12, width: '100%', textAlign: 'initial', paddingLeft: 10 }}>{"Modüller : "}
                <span style={{ fontSize: 13, fontWeight: '400' }}>
                  {licenceData.ModuleList.ModuleListItem.filter(x => licenceData.SubscriptionInfo.Module.filter(t => t.Id == x.Id).length != 0).map(x => x.Name).join(', ')}
                </span>
              </div>
              <div style={{ fontWeight: '600', fontSize: 12, width: '100%', textAlign: 'initial', paddingLeft: 10 }}>{"Hizmetler : "}
                <span style={{ fontSize: 13, fontWeight: '400' }}>
                  {"Kurulum Ücreti Yok, Bakım Ücreti Yok, Yıllık Yenileme Ücreti Yok, Güncelleme Ücreti Yok, 7/24 Canlı Destek"}
                </span>
              </div>
            </div>
            <Button style={{ borderRadius: 5, height: 45, textTransform: 'capitalize' }} variant="contained" onClick={() => this.props.ModuleOpen().then(() => { })}>
              <Icon style={{ color: '#424242', marginRight: 10 }}>{"extension"}</Icon>
              {"Modül Detayları"}
            </Button>
          </div>
        </div>
        <div style={{ padding: 10, width: '100%', height: 50, backgroundColor: '#3d3d3d', fontSize: 20, color: 'white' }}>
          <img src={paynet} /> <span style={{ fontSize: 12, marginLeft: 20 }}>{"Ödeme alt yapısı ve kart güvenlik gereksinimleri Türkiye'nin en büyük ödeme hizmetleri sağlayıcısı TCMB lisanslı Paynet çözüm ortağımız tarafından sağlanmaktadır."}</span>
        </div>
      </Dialog >
    );

  }
}

Licence.propTypes = {
  classes: PropTypes.object.isRequired
};

const valueSelector = (state, props) => state.get('licenceReducer').IsLicenceUpdate;
const value = createSelector([valueSelector],
  (value) => value);

const mapStateToProps = (state, props) => ({
  IsLicenceUpdate: value(state, props),
  IsLicenceVisible: state.get('licenceReducer').IsLicenceVisible,
  licenceData: state.get('licenceReducer').licenceData,
  IsModuleOpen: state.get('licenceReducer').IsModuleOpen,
  IsPaymentDetailOpen: state.get('licenceReducer').IsPaymentDetailOpen,
  IsCardOpen: state.get('licenceReducer').IsCardOpen,
  cardList: state.get('licenceReducer').cardList,
  bankStart: state.get('licenceReducer').bankStart,

  modalLicenceOpen: state.get('licenceReducer').form_dataLicence.modalLicenceOpen,
  modalLicenceText: state.get('licenceReducer').form_dataLicence.modalLicenceText,
  modalLicenceTitle: state.get('licenceReducer').form_dataLicence.modalLicenceTitle,

  invoiceVisible: state.get('licenceReducer').invoiceVisible,
  invoiceData: state.get('licenceReducer').invoiceData,

  sendLinkVisible: state.get('licenceReducer').sendLinkVisible,
  sendLinkData: state.get('licenceReducer').sendLinkData
})

const mapDispatchToProps = {
  formDataSetLicence,
  setLicenceVisible,
  ModuleOpen,
  setSubscriptionData,
  PaymentDetailOpen,
  CardOpen,
  getPaymentUrl,
  setCardInfoDelete,
  setManuelCharge,
  setCardInfoArrange,
  setModalLicense,

  InvoiceVisible,
  getInvoiceHeader,
  setInvoiceHeader,
  setInvoiceData,
  setLicenceData,
  setSendLinkVisible,
  setSendPaymentLink
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(Licence)));
