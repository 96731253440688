import React, { Fragment, useState, memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import styles from '../../CenterBrandPage/centerbrandpage-jss';
import {
    setPaketYoldaBrandAddData, setPaketYoldaBrandAddVisible, setAddBrand
} from '../../../../redux/modules/paketyolda';
import { getAddressList } from '../../../../redux/modules/addition';
import { createSelector } from 'reselect';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import 'react-credit-cards/es/styles-compiled.css';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);
import DatePicker, { registerLocale } from "react-datepicker";
import { CapitalizeText, ComplateLowerText } from '../../../../function/GeneralFunction';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

class BrandAdd extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.paketYoldaBrandAddUpdate == this.props.paketYoldaBrandAddUpdate) {
            return false;
        }
        return true;
    }

    async componentDidMount() {
        this.handleSubmitBrandAdd = this.handleSubmitBrandAdd.bind(this);
    }

    handleChangePaketYoldaBrand = (Name) => (event) => {
        const pointer = event.target.selectionStart;
        const element = event.target;

        const { paketYoldaBrandAddData } = this.props;
        if(Name == "CreditToBeLoaded" && event.target.value == 0){
            return;
        }
        paketYoldaBrandAddData[Name] = (Name == "IsActive" || Name == "IsOfficialDistributor" ? event.target.checked : CapitalizeText(event.target.value));
        this.props.setPaketYoldaBrandAddData(paketYoldaBrandAddData).then(() => { })

        window.requestAnimationFrame(() => {
            element.selectionStart = pointer;
            element.selectionEnd = pointer;
        });
    };

    handleSubmitBrandAdd(event) {
        event.preventDefault();
        this.props.setAddBrand().then(() => { })
    }

    getIlIds() {
        const { IlList } = this.props;
        var list = [];
        if (IlList.length == 0) {
            return list;
        }

        IlList.map((n, i) => {
            if (n.name.length > 0) {
                list.push({ value: n.value, label: n.name });
            }
        })
        return list;
    }

    async onChangeMultiIlIds(n) {
        const { paketYoldaBrandAddData } = this.props;
        await this.props.getAddressList("Ilce", n.value).then(() => { })
        paketYoldaBrandAddData.IlId = n.value;
        paketYoldaBrandAddData.IlceIds = [];
        this.props.setPaketYoldaBrandAddData(paketYoldaBrandAddData).then(() => { })
    }

    getIlceIds() {
        const { IlceList } = this.props;
        var list = [];
        if (IlceList.length == 0) {
            return list;
        }

        IlceList.map((n, i) => {
            if (n.name.length > 0) {
                list.push({ value: n.value, label: n.name });
            }
        })
        return list;
    }

    onChangeMultiIlceIds(value) {
        const { paketYoldaBrandAddData } = this.props;
        var list = [];
        value.map((n, i) => {
            list.push(n.value);
        })
        paketYoldaBrandAddData.IlceIds = list;
        this.props.setPaketYoldaBrandAddData(paketYoldaBrandAddData).then(() => { })
    }

    render() {
        const { IlceList, IlList, classes, paketYoldaBrandAddVisible, paketYoldaBrandAddData, paketYoldaBrandAddLoading } = this.props;

        var IlIds = [];
        if (IlList.length > 0 && IlList.filter(x => x.value == paketYoldaBrandAddData.IlId).length > 0 && paketYoldaBrandAddData.IlId.length > 0) {
            IlIds = { value: paketYoldaBrandAddData.IlId, label: IlList.find(x => x.value == paketYoldaBrandAddData.IlId).name };
        }
        var IlceIds = [];
        if (IlceList.length > 0) {
            if (paketYoldaBrandAddData.IlceIds.length == 1) {
                if (IlceList.filter(x => x.value == paketYoldaBrandAddData.IlceIds[0]).length > 0) {
                    IlceIds.push({ value: paketYoldaBrandAddData.IlceIds[0], label: IlceList.find(x => x.value == paketYoldaBrandAddData.IlceIds[0]).name });
                }
            } else {
                if (paketYoldaBrandAddData.IlceIds.indexOf(',') == -1) {
                    if (IlceList.filter(x => x.value == paketYoldaBrandAddData.IlceIds).length > 0) {
                        IlceIds.push({ value: paketYoldaBrandAddData.IlceIds, label: IlceList.find(x => x.value == paketYoldaBrandAddData.IlceIds).name });
                    }
                } else {
                    paketYoldaBrandAddData.IlceIds.split(",").map((n, i) => {
                        if (IlceList.filter(x => x.value == n).length > 0) {
                            IlceIds.push({ value: n, label: IlceList.find(x => x.value == n).name });
                        }
                    })
                }
            }
        }

        return (
            <Dialog
                open={paketYoldaBrandAddVisible}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'sm'}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
            >
                <form autocomplete="off" style={{ display: 'contents' }} onSubmit={this.handleSubmitBrandAdd}>
                    <DialogTitle style={{ padding: '24px 24px 5px', marginBottom: 10 }}>
                        {"Bayi Ekleme/Düzenleme"}
                    </DialogTitle>
                    <DialogContent style={{ overflow: 'hidden auto', height: '100vh' }}>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={paketYoldaBrandAddData.IsActive}
                                            onChange={this.handleChangePaketYoldaBrand("IsActive")}
                                        />
                                    )}
                                    label={"Aktif"}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            disabled
                                            checked={paketYoldaBrandAddData.IsOfficialDistributor}
                                        />
                                    )}
                                    label={"Ana Bayi"}
                                />
                            </Grid>
                            <Grid item md={6} style={{ color: '#424242' }}>
                                <ReactMultiSelectCheckboxes
                                    onChange={(value) => this.onChangeMultiIlIds(value)}
                                    width={"100%"}
                                    isMulti={false}
                                    value={IlIds}
                                    options={this.getIlIds()}
                                    placeholderButtonLabel={"İl Seçimi"}
                                />
                            </Grid>
                            <Grid item md={6} style={{ color: '#424242' }}>
                                <ReactMultiSelectCheckboxes
                                    onChange={(value) => this.onChangeMultiIlceIds(value)}
                                    width={"100%"}
                                    options={this.getIlceIds()}
                                    value={IlceIds}
                                    placeholderButtonLabel={"İlçe Seçimi"}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Şirket Ünvanı'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Şirket Ünvanını Giriniz'}
                                        value={paketYoldaBrandAddData.CompanyTitle}
                                        onChange={this.handleChangePaketYoldaBrand('CompanyTitle')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Marka Adı'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Marka Adı Giriniz'}
                                        value={paketYoldaBrandAddData.BrandName}
                                        onChange={this.handleChangePaketYoldaBrand('BrandName')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Yetkili Kişi'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Yetkili Kişi Giriniz'}
                                        value={paketYoldaBrandAddData.AuthorizedPerson}
                                        onChange={this.handleChangePaketYoldaBrand('AuthorizedPerson')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <InputMask
                                    mask="0 999 999 99 99"
                                    onFocus={event => { event.target.select() }}
                                    value={paketYoldaBrandAddData.Phone}
                                    onChange={this.handleChangePaketYoldaBrand("Phone")}
                                >
                                    {() => <TextField
                                        label={'Telefon Numarası'}
                                        name={"Phone"}
                                        fullWidth
                                        required
                                        style={{ marginTop: 0 }}
                                        margin="normal"
                                        type="text"
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Adres'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Adres Giriniz'}
                                        value={paketYoldaBrandAddData.Address}
                                        onChange={this.handleChangePaketYoldaBrand('Address')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Açıklama'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Açıklama Giriniz'}
                                        value={paketYoldaBrandAddData.Explanation}
                                        onChange={this.handleChangePaketYoldaBrand('Explanation')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Vergi Dairesi'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Vergi Dairesi Giriniz'}
                                        value={paketYoldaBrandAddData.TaxAdministration}
                                        onChange={this.handleChangePaketYoldaBrand('TaxAdministration')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Vergi Numarası'}</InputLabel>
                                    <Input
                                        fullWidth
                                        required
                                        placeholder={'Vergi Numarası Giriniz'}
                                        value={paketYoldaBrandAddData.TaxNumber}
                                        onChange={this.handleChangePaketYoldaBrand('TaxNumber')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                <FormControl fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="name-simple">{'Tanımlanacak Kredi Miktarı'}</InputLabel>
                                    <Input
                                        type={"number"}
                                        required
                                        placeholder={'Kredi Miktarı Giriniz'}
                                        value={paketYoldaBrandAddData.CreditToBeLoaded}
                                        inputProps={{
                                            min: 5000
                                        }}
                                        onChange={this.handleChangePaketYoldaBrand('CreditToBeLoaded')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={paketYoldaBrandAddLoading} type={"submit"} variant="contained" color="secondary">
                            {"Kaydet"}
                        </Button>
                        <Button variant="contained" style={{ backgroundColor: '#F44336' }} onClick={() => this.props.setPaketYoldaBrandAddVisible(false).then(() => { })} color="primary">
                            {"Çıkış"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

BrandAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

const valueSelector = (state, props) => state.get('paketYoldaReducer').paketYoldaBrandAddUpdate;
const value = createSelector([valueSelector], (value) => value);

const mapStateToProps = (state, props) => ({
    paketYoldaBrandAddUpdate: value(state, props),
    paketYoldaBrandAddVisible: state.get('paketYoldaReducer').paketYoldaBrandAddVisible,
    paketYoldaBrandAddLoading: state.get('paketYoldaReducer').paketYoldaBrandAddLoading,
    paketYoldaBrandAddData: state.get('paketYoldaReducer').paketYoldaBrandAddData,
    IlList: state.get('additionReducer').form_data.IlList,
    IlceList: state.get('additionReducer').form_data.IlceList
});

const mapDispatchToProps = {
    setPaketYoldaBrandAddVisible,
    setPaketYoldaBrandAddData,
    setAddBrand,
    getAddressList
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(injectIntl(BrandAdd)));
